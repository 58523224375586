import React from 'react';
// import i18n from "./src/locales";
import Layout from "./src/components/Layout";

import 'normalize.css';
import './src/css/global.css'

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
