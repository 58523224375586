import { UPDATE_SHOW_CALCULATOR } from '../actions';

const initialState = false;

const showCalculatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SHOW_CALCULATOR:
      return action.payload;
    default:
      return state;
  }
};

export default showCalculatorReducer;
