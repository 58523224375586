import { combineReducers } from 'redux';

import resetReducer from "./resetReducer";
import showCalculatorReducer from "./showCalculatorReducer";
import typeDevReducer from "./typeDevReducer";
import typeReducer from "./typeReducer";
import typeChildReducer from "./typeChildReducer";
import designReducer from "./designReducer";
import stepsReducer from "./stepsReducer";
import commentReducer from "./commentReducer";

const rootReducer = combineReducers({
  reset: resetReducer,
  showCalculator: showCalculatorReducer,
  typeDev: typeDevReducer,
  type: typeReducer,
  typeChild: typeChildReducer,
  design: designReducer,
  steps: stepsReducer,
  comment: commentReducer
});

export default rootReducer;
