import { UPDATE_COMMENT } from '../actions';

const initialState = '';

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COMMENT:
      return action.payload;
    default:
      return state;
  }
};

export default commentReducer;
